import React,{useState,useEffect,lazy} from 'react';
import axios from 'axios'
import {  Slider,Divider,Statistic,Collapse,Avatar,Tooltip,Button } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined,CreditCardFilled,MessageFilled,LoadingOutlined,
   UserOutlined, AccountBookOutlined,SlidersFilled,MinusCircleFilled,BankFilled,DropboxOutlined,
   DollarCircleOutlined,GroupOutlined,FileFilled,DollarCircleFilled,MinusSquareFilled,FundFilled,PlusSquareFilled } from '@ant-design/icons';
import moment from 'moment';
import { Line,Pie,Bar } from 'react-chartjs-2';
import {withGetScreen} from 'react-getscreen'
import {
  Col,
  Row,
} from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import * as serverconfig from '../../views/serverconn'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';
import { NavLink } from 'react-router-dom'
import { Table, Input,Popover,Modal,Tabs
  ,Card,Form,Select,InputNumber,Drawer,Descriptions,Popconfirm,message,Spin } from 'antd';
import Saccocreateform from '../components/saccocreateform'
import Packagecreateform from '../components/packagecreateform'

import { Link } from 'react-router-dom';

const brandPrimary = getStyle('--primary')
const brandSuccess = getStyle('--success')
const brandInfo = getStyle('--info')
const brandWarning = getStyle('--warning')
const brandDanger = getStyle('--danger')


const style = { background: '#0092ff', padding: '8px 0',borderRadius: '7px',justifyContent:'center', alignItems:'center',display: 'flex', };
const dateFormat = 'DD/MM/YYYY';
const yeardateFormat = 'YYYY';
const monthdateFormat = 'MM/YYYY';

const { Panel } = Collapse;
const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class AdminIndex extends React.Component {

  state = {
    expenses:[],
    searchText: '',
    searchedColumn: '',
    date:moment().format(dateFormat).toString(),
    yearlymonths:[],
    yearlymonthlysales:[],
    totalnoofcompanies:0,
    monthlyinvoicetotalamount:0,
    showprintermodal:false,
    invoicesbycompany:[],
    companies:[],
    invoicetotals:[],
    licencedcompanies:[],
    companylicences:[],
    companyprofile:{},
    datarequested:true,
    totalnoofpackages:0,
    showpackagecreatemodal:false

  };

  callback =(key) =>{
    console.log(key);
    console.log(this.state.yearlymonthlysales)
  }

  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    console.log("token: "+token)

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/saccos/`)
    .then(res => {
        this.setState({
          totalnoofcompanies:res.data.length
        })
       
    })

    axios.get(serverconfig.backendserverurl+`/api/packages/`)
    .then(res => {
        this.setState({
          totalnoofpackages:res.data.length
        })
       
    })

        ///////////////////////////////////////////////////////////////////////////////
    /// getting the expense amounts by category
    let form_data2 = new FormData();
    form_data2.append('month',moment().format(monthdateFormat).toString());
 

      //Now submit sale data to database
      axios.get(serverconfig.backendserverurl+'/customqueries/getlicencesbycompany',form_data2,{
        headers: {
          'content-type': 'multipart/form-data'
        }
          })
          .then(res =>{

              const companys = [];
              const companylicences = [];

              JSON.parse(res.data.licencereport).map(
                (item)=>{
                  companys.push(item.company_name)
                  companylicences.push(Number(item.licencedays))
                })

                this.setState({
                  licencedcompanies:companys})

                this.setState({
                  companylicences:companylicences}) 
          } 
          )
          .catch(error => console.log(error)) 

    //get the company profile here
    axios.get(`${serverconfig.backendserverurl}/api/companyprofile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

        this.setState({datarequested:false})
    })


}

//display sacco create modal
displaypaymentmodal=()=>{
  this.setState({showprintermodal:true})

}

closeprintermodal=()=>{
  this.setState({showprintermodal:false})
}

//display package create modal
displaypackagecreateModal=()=>{
  this.setState({showpackagecreatemodal:true})
}

closepackagecreateModal=()=>{
  this.setState({showpackagecreatemodal:false})
}




render() {

  const  chartData1={
    labels:this.state.licencedcompanies,
    datasets:[{
      label:'Company Licence',
      data:this.state.companylicences,
      backgroundColor:[
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)'
    ],
    borderWidth: 1
    }]
  }

  if(this.state.datarequested===true){
    return(
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
      <Spin indicator={antIcon} />
      </div>
    )

  }else{

    return(
    
      <div className="animated fadeIn">
      <Row>
      <Col xs="12" sm="6" lg="4" style={{padding: '5px'}}>
       <Card
          //onClick={()=>{}}
          hoverable
          style={{ 
            background:"white",
            display: 'flex',
            justifyContent:'center',
            padding:4,
            alignItems:'center' }}
          cover={<GroupOutlined  style={{color:'#154360',fontSize: '50px'}} />} 
        >
          <Meta title={<h3 style={{color:"#154360"}}><Button type='primary' onClick={() => this.displaypaymentmodal()}>Add Sacco</Button></h3>} description={<h5><NavLink to='/saccos/'>{this.state.totalnoofcompanies} SACCOS </NavLink></h5>} />
        </Card>
       </Col>
      
       <Col xs="12" sm="6" lg="4" style={{padding: '5px'}}>
       <Card
          hoverable
          style={{ 
            display: 'flex',
            justifyContent:'center',
            padding:15,
            alignItems:'center' }}
          cover={<DropboxOutlined  style={{color:'#35B658',fontSize: '50px'}} />} 
        >
        <Meta title={<h3 style={{color:"#154360"}}><Button type='primary' onClick={() => this.displaypackagecreateModal()}>Add Package</Button></h3>} description={<h5><NavLink to='/packages/'> {this.state.totalnoofpackages} PACKAGES </NavLink></h5>} />

        </Card>
      
       </Col>
      
       <Col xs="12" sm="6" lg="4" style={{padding: '5px'}}>
       <Card
          hoverable
          style={{ 
            display: 'flex',
            justifyContent:'center',
            padding:15,
            alignItems:'center' }}
          cover={<MessageFilled  style={{color:'#FF6347',fontSize: '50px'}} />} 
        >
          <Meta title={<h3>{this.state.companyprofile.smsbalance}</h3>} description={"Total of SMS Messages"} />
        </Card>
      
       </Col>
      
       </Row>
       <br></br>
       <Card
          hoverable
        >
           <Bar
              data={chartData1}
              options={{
                maintainAspectRatio:true,
                title:{
                  display:true,
                  text: 'Licences by Sacco',
                  fontSize:20
                }
              }}
              />
      
        </Card>
      
       <Modal              
          visible={this.state.showprintermodal}
          title="Sacco Registration"
          width={600}
          onCancel={this.closeprintermodal}
      
          footer={[
            <Button key="back" onClick={this.closeprintermodal}>
              Cancel
            </Button>
            ]}
            >
              <Saccocreateform />
      
                            
      </Modal>
      

      <Modal              
          visible={this.state.showpackagecreatemodal}
          title="Package Creation"
          width={600}
          onCancel={this.closepackagecreateModal}
      
          footer={[
            <Button key="back" onClick={this.closeprintermodal}>
              Cancel
            </Button>
            ]}
            >
            <Packagecreateform />
              
      </Modal>


      </div>
      
      )
  }



  }
}

export default AdminIndex; 
