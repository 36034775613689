import React from 'react';
import { Form, Input, Button,Select,message,Spin } from 'antd';
import { Table,Collapse,Popover,Popconfirm,Descriptions,Modal } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import * as serverconfig from '../../views/serverconn'
import moment from 'moment';

import { SearchOutlined,LoadingOutlined,DeleteOutlined,FundViewOutlined,WalletFilled,EditOutlined } from '@ant-design/icons';

const dateFormat = 'HHmmssDDMMYYYY';

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''


class Saccocreateform extends React.Component {

  state = {
    companyname:'',  
    licence:'',
    companyemail:'',
    companylogo:null,   
    companyphone:'',
    boxnumber:'',
    accountslimit:0,
    branchname:'',
    companyphonetwo:'',
    companyphonethree:'',
    website:'',
    address:'',
    datarequested:true,  
    safebalance:0,
    sharevalue:0,
    retainedearnings:0,
    packages:[],
    package:'',
    mobilemoneygatewaycode:''

  }


  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+'/api/packages/')
    .then(res => {
        this.setState({
          packages:res.data
        })

        this.setState({datarequested:false});
    })
}


  //submit button pressed
  handleFormSubmit=(event) =>{

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('sacco_name', this.state.companyname);
    form_data.append('branchname', this.state.branchname);
    form_data.append('company_email', this.state.companyemail);
    form_data.append('RegisteredPhoneNumber1', this.state.companyphone);
    form_data.append('box_number', this.state.boxnumber);
    form_data.append('licencedays', this.state.licence);
    form_data.append('accountslimit',this.state.accountslimit);
    this.state.companylogo==null?

    console.log("No profile file")
    :
    form_data.append('sacco_logo', this.state.companylogo,this.state.companylogo?this.state.companylogo.name:"");
    
    form_data.append('website',this.state.website);
    form_data.append('RegisteredPhoneNumber2', this.state.companyphonetwo);
    form_data.append('RegisteredPhoneNumber3', this.state.companyphonethree);

    form_data.append('safe_balance', this.state.safebalance);
    form_data.append('sharevalue', this.state.sharevalue);
    form_data.append('retained_earnings', this.state.retainedearnings);
    form_data.append('country', 'Uganda');
    form_data.append('currency', 'UGX');
    form_data.append('address',this.state.address);
    form_data.append('package',this.state.package);

    this.state.usesms?
    form_data.append('usesms', true)
    :
    form_data.append('usesms', false);


    form_data.append('activated', false);
    form_data.append('roundoffvalue',100);

    form_data.append('showdream', false);
    form_data.append('showchildren', false);
    form_data.append('showemergency', false);
    form_data.append('showlegacy', false);
    form_data.append('showuniversity', false);
    form_data.append('showwelfare', false);
    form_data.append('showsubaccounttransfer', false);

    form_data.append('sharetransfercharge', 0);
    form_data.append('smsmessages', 0);

    form_data.append('automatedloanrepayment', false);
    form_data.append('smsonautomatedloanrepayment', false);
    form_data.append('loanrepaymentalertdays', 3);
    form_data.append('automatedloanreminders', false);
    form_data.append('dataidentificationcode',"SC"+moment().format(dateFormat).toString() );
    form_data.append('showgroupaccounts', false);
    form_data.append('showeditablewithdrawcharge', false);
    form_data.append('sharesfeature', false);
    form_data.append('userbalancesfeature', false);

    form_data.append('mobilemoneygatewaycode',this.state.mobilemoneygatewaycode);

    
      axios.post(serverconfig.backendserverurl+'/api/saccos/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res => this.setState({datarequested:false})
    , window.location.reload(false),message.info('Successfully created sacco')
    )
    .catch(error => console.log(error))

  }

  handleCompanyNameChange= (e) => this.setState({ companyname: e.target.value});
  handleLicenceChange= (e) => this.setState({ licence: e.target.value});
  handleCompanyEmailChange= (e) => this.setState({ companyemail: e.target.value});
  handleCompanyLogoChange= (e) => this.setState({ companylogo: e.target.files[0]});

  handleCompanyPhoneChange= (e) => this.setState({ companyphone: e});
  handleCompanyBoxNumberChange= (e) => this.setState({ boxnumber: e.target.value});
  handleaccountslimitChange=(e) => this.setState({ accountslimit: e.target.value});
  handlebranchnameChange=(e) => this.setState({ branchname: e.target.value});
  handleentitytypeChange= (e) => this.setState({ entitytype: e});
  
  handleCompanyPhonetwoChange= (e) => this.setState({ companyphonetwo: e});
  handleCompanyPhonethreeChange= (e) => this.setState({ companyphonethree: e});
  handlecompanydealershipChange= (e) => this.setState({ companydealership: e.target.value});
  handlewebsiteChange= (e) => this.setState({ website: e.target.value});
  handleappbannerChange= (e) => this.setState({ appbanner: e.target.files[0]});
  handlevendorpaymentvalueChange=(e)=>this.setState({vendorpaymentvalue:e.target.value})
  
  handledeliveryagencyChange= (e) => this.setState({ deliveryagency: e});


  handleAddressChange= (e) => this.setState({ address: e.target.value});
  handleretainedearningsChange= (e) => this.setState({ retainedearnings: e.target.value});
  handlesharevalueChange= (e) => this.setState({ sharevalue: e.target.value});
  handlesafebalanceChange= (e) => this.setState({ safebalance: e.target.value});
  
  handlepackageChange= (e) => this.setState({ package: e});

  handlemobilemoneygatewaycodeChange= (e) => this.setState({ mobilemoneygatewaycode: e.target.value});

  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div >
          <Form
          onFinish={(event) => this.handleFormSubmit(event)}
          >
            <FormItem label="Sacco Name"
                name="sacconame"
                rules={[
                  {
                    required: true,
                    message: 'Please input name',
                  },
                ]}
              >
              <Input name="sacconame"  placeholder="Put a name here." value={this.state.companyname} onChange={this.handleCompanyNameChange} />
            </FormItem>
  
  
            <FormItem label="Branch Name"
                name="branchname"
                rules={[
                  {
                    required: true,
                    message: 'Please input branch name',
                  },
                ]}
              >
              <Input  placeholder="Put a name here." value={this.state.branchname} onChange={this.handlebranchnameChange} />
            </FormItem>
  
            <FormItem label="Sacco Logo">
              <Input name="companylogo" type="file" accept="image/png, image/jpeg" placeholder="Company logo"  onChange={this.handleCompanyLogoChange}/>
            </FormItem>  
            
            <FormItem label="Sacco Email"
                name="companyemail"
                rules={[
                  {
                    required: true,
                    message: 'Please input email',
                  },
                ]}
            >
              <Input name="companyemail" type="email"  placeholder="company@email.com" value={this.state.companyemail} onChange={this.handleCompanyEmailChange} />
            </FormItem> 
  
            <FormItem label="Phone Number">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.companyphone} onChange={this.handleCompanyPhoneChange}/>
             </FormItem>
             <FormItem label="Phone Number Two">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.companyphonetwo} onChange={this.handleCompanyPhonetwoChange}/>
             </FormItem> 
  
             <FormItem label="Phone Number Three">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.companyphonethree} onChange={this.handleCompanyPhonethreeChange}/>
             </FormItem>  
             <FormItem label="Website">
              <Input  name="website"  placeholder="Webiste here" value={this.state.website} onChange={this.handlewebsiteChange} />
            </FormItem>
  
            <FormItem label="Box number">
              <Input name="boxnumber"  placeholder="Box number here" value={this.state.boxnumber} onChange={this.handleCompanyBoxNumberChange} />
            </FormItem>
  
            <FormItem label="Address">
              <Input name="address"  placeholder="Address here" value={this.state.address} onChange={this.handleAddressChange} />
            </FormItem>

            <FormItem label="Licence"
                  name="licence"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter licence days',
                    },
                  ]}
            >
              <Input name="licence"  placeholder="Licence days" value={this.state.licence} onChange={this.handleLicenceChange} />
            </FormItem>
  
            <FormItem label="Accounts Limit"
                  name="accountslimit"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter accounts limit',
                    },
                  ]}
            >
              <Input name="accountslimit"  placeholder="Accounts Limit" value={this.state.accountslimit} onChange={this.handleaccountslimitChange} />
            </FormItem>
  
            <FormItem label="Safe Balance"
                  name="safebalance"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Amount',
                    },
                  ]}
            >
              <Input name="safebalance"  placeholder="Safe Balance" value={this.state.safebalance} onChange={this.handlesafebalanceChange} />
            </FormItem>

            <FormItem label="Share Value"
                  name="sharevalue"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Amount',
                    },
                  ]}
            >
              <Input name="sharevalue"  placeholder="Share Value" value={this.state.sharevalue} onChange={this.handlesharevalueChange} />
            </FormItem>

           

            <FormItem label="Retained Earnings"
                  name="retained_earnings"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Amount',
                    },
                  ]}
            >
              <Input name="retained_earnings"  placeholder="Retained Earnings" value={this.state.retainedearnings} onChange={this.handleretainedearningsChange} />
            </FormItem>

            <FormItem label="Package"
            name="package"
            rules={[
              {
                required: true,
                message: 'Please select package',
              },
            ]}            
            
            >
                <Select placeholder="Package"  value={this.state.package} onChange={this.handlepackageChange} >
                  {this.state.packages.map(
                    (pckg)=>(
                      <Option value={pckg.id}>{pckg.package_name}</Option>
                    ))}
                </Select>
            </FormItem>

            <FormItem label="Mobile Money Gateway Code">
              <Input name="mmcode"  placeholder="Gate way Code" value={this.state.mobilemoneygatewaycode} onChange={this.handlemobilemoneygatewaycodeChange} />
            </FormItem>


            <FormItem>
              <Button type="primary" htmlType="submit" >Create</Button>
            </FormItem>
          </Form>
        </div>
      );
    }


   
  }

}


export default Saccocreateform;



